import InputError from "../components/InputError.vue";
import LoaderTailSpin from "../components/LoaderTailSpin.vue";
import hapiMixins from "@juno0424/vue2-hapi";

let components = {
  [InputError.name]: InputError,
  [LoaderTailSpin.name]: LoaderTailSpin,
};

function install(Vue, options) {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
  });
}

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use({ install });
}

export default {
  install,
};

export { InputError, LoaderTailSpin, hapiMixins };
